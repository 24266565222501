<template>
  <v-data-table
    :headers="headers"
    :items="driverKasbons"
    sort-by="no"
    dense
    @click:row="onClickRow"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="success" v-on="on" dark rounded :to="{ name: 'Create Driver Kasbon' }">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Create</span>
        </v-tooltip>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ driverKasbons.findIndex(x => x === item) + 1 }}
    </template>
    <template v-slot:item.totalAmount="{ item }">
      {{ formatPrice(item.totalAmount) }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "driver-kasbon",
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Date",
        value: "kasbonDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Kasbon Number",
        value: "kasbonNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Kasbon Name",
        value: "name",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Method",
        value: "payment",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Total",
        value: "totalAmount",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  computed: {
    ...mapState("driverKasbon", ["driverKasbons"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      await this.$store
        .dispatch("driverKasbon/getAll")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Driver Kasbon", params: { id: item.id } });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
